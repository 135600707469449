import logo from './logo.svg';
import './App.css';
import ImageSection from './components/imagesection';
import UploadSection from './components/uploadsection';
import FAQSection from './components/faqsection';
import Navbar from './components/navbar';

function App() {
  return (
    <div style={{ overflowX: 'hidden' }} className="">
      <Navbar />
      <ImageSection />
      <UploadSection />
      <FAQSection />
    </div>
  );
}

export default App;
