import React, { useState, useEffect, useRef } from 'react';
import './uploadsection.css';
import { FileUploader } from 'react-drag-drop-files';

const UploadSection = () => {
    const fileTypes = ["JPG", "PNG", "GIF", 'AVIF'];
    const [selectedColor, setSelectedColor] = useState('');
    const [firstColor, setFirdColor] = useState('')
    const [secondColor, setSecondColor] = useState('')
    const [thirdColor, setThirdColor] = useState('')
    const [colorBox, setColorBox] = useState(1)
    const [customCurser, setCustomCurser] = useState(false)
    const [position, setPosition] = useState({ x: -100, y: -100 });
    const [step, setStep] = useState(1)
    const [image, setImage] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [crop, setCrop] = useState({ x: 0, y: 0, radius: 50 });
    const [isDragging, setIsDragging] = useState(false);
    const [isResizing, setIsResizing] = useState(false);
    const canvasRef = useRef(null);
    const resultCanvasRef = useRef(null);
    const dashLength = 5; // Length of each dash
    const gapLength = 5;



    useEffect(() => {
        const onMouseMove = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };

        window.addEventListener('mousemove', onMouseMove);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
        };
    }, []);

    useEffect(() => {
        // Function to update window width when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            console.log(window.innerWidth);
        };

        // Add an event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (image && step === 1) {

            const ctx = canvasRef.current.getContext('2d');
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            ctx.drawImage(image, 0, 0, image.width, image.height);

            // Draw the circular crop area
            // ctx.beginPath();
            // ctx.arc(crop.x, crop.y, crop.radius, 0, 2 * Math.PI);
            // ctx.strokeStyle = 'white';
            // ctx.lineWidth = 2;
            // ctx.stroke();
            // ctx.closePath();

            // Set the line style to dashed
            ctx.setLineDash([dashLength, gapLength]);

            // Draw the circle with a dashed border
            ctx.strokeStyle = 'white';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.arc(crop.x, crop.y, crop.radius, 0, Math.PI * 2);
            ctx.stroke();
            ctx.closePath();
        }
    }, [image, crop, step]);

    const handleImageUpload = (file) => {
        // const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                setImage(img);
                console.log(img.width, img.height)
                setCrop({
                    x: windowWidth > 740 ? img.width < 720 ? img.width / 2 : 360 : windowWidth / 2, // Initialize the crop circle at the center of the image
                    y: windowWidth > 740 ? img.height < 720 ? img.height / 2 : 360 : windowWidth / 2,
                    radius: 60, // Initialize with a default radius
                });
            };
        };

        reader.readAsDataURL(file);
    };

    const handleMouseDown = (e) => {
        const rect = canvasRef.current.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;

        // Check if the mouse click is inside the crop circle's boundary
        const distanceToCenter = Math.sqrt(
            Math.pow(offsetX - crop.x, 2) + Math.pow(offsetY - crop.y, 2)
        );

        if (distanceToCenter > crop.radius - 5 && distanceToCenter < crop.radius + 5) {
            // If the mouse is near the boundary of the circle, start resizing
            setIsResizing(true);
        } else if (distanceToCenter <= crop.radius) {
            // If the mouse click is inside the crop circle, start dragging
            setIsDragging(true);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setIsResizing(false);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const rect = canvasRef.current.getBoundingClientRect();
            const offsetX = e.clientX - rect.left;
            const offsetY = e.clientY - rect.top;

            // Calculate the new x and y positions, taking the circle's radius into account
            let newX = offsetX;
            let newY = offsetY;

            // Ensure the entire circle stays within the canvas boundaries
            newX = Math.max(crop.radius, Math.min(canvasRef.current.width - crop.radius, newX));
            newY = Math.max(crop.radius, Math.min(canvasRef.current.height - crop.radius, newY));

            const newCrop = { ...crop, x: newX, y: newY };
            // const newCrop = { ...crop };
            // newCrop.x = e.clientX - rect.left;
            // newCrop.y = e.clientY - rect.top;
            setCrop(newCrop);
        } else if (isResizing) {
            const rect = canvasRef.current.getBoundingClientRect();
            const offsetX = e.clientX - rect.left;
            const offsetY = e.clientY - rect.top;

            // Calculate the new radius based on the distance from the center
            const newRadius = Math.sqrt(
                Math.pow(offsetX - crop.x, 2) + Math.pow(offsetY - crop.y, 2)
            );
            const maxRadius = Math.min(canvasRef.current.width, canvasRef.current.height) / 2
            if (newRadius >= 50 && newRadius < maxRadius) {
                // Prevent the radius from becoming too small
                setCrop({ ...crop, radius: newRadius });
            }
        }
    };

    const handleMouseOut = () => {
        setIsDragging(false);
        setIsResizing(false);
    };

    const handleCropImage = () => {
        if (image) {
            setStep(2)
            return;
        }
        alert('upload Image')
    };

    useEffect(() => {
        if (image && step === 2) {
            const resultCanvas = resultCanvasRef.current;
            const ctx = resultCanvas.getContext('2d');

            // Set the dimensions of the result canvas to match the canvas size
            resultCanvas.width = resultCanvas.clientWidth;
            resultCanvas.height = resultCanvas.clientHeight;

            // Draw the cropped portion of the image to fill the canvas
            ctx.drawImage(
                image,
                crop.x - crop.radius, // x-coordinate of the source image
                crop.y - crop.radius, // y-coordinate of the source image
                crop.radius * 2, // width of the source image to crop
                crop.radius * 2, // height of the source image to crop
                0, // x-coordinate of the destination canvas
                0, // y-coordinate of the destination canvas
                resultCanvas.width, // width of the destination canvas
                resultCanvas.height // height of the destination canvas
            );
        }
    }, [step])


    const handleCanvas = (event) => {
        const canvas = event.target;
        const ctx = canvas.getContext('2d');
        const x = event.nativeEvent.offsetX;
        const y = event.nativeEvent.offsetY;

        const pixel = ctx.getImageData(x, y, 1, 1).data;
        const color = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;

        setSelectedColor(color);
    };
    const handleCanvasClick = (event) => {
        const canvas = event.target;
        const ctx = canvas.getContext('2d');
        const x = event.nativeEvent.offsetX;
        const y = event.nativeEvent.offsetY;

        const pixel = ctx.getImageData(x, y, 1, 1).data;
        const color = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;

        setSelectedColor(color);
        if (colorBox === 1) {
            setFirdColor(color)
            if (secondColor) {
                setColorBox(4)
                setCustomCurser(false)
            }
            else {
                setColorBox(2)
            }
        }
        else if (colorBox === 2) {
            setSecondColor(color)
            if (thirdColor) {
                setColorBox(4)
                setCustomCurser(false)
            }
            else {
                setColorBox(3)
            }
        }
        else if (colorBox === 3) {
            setThirdColor(color)
            setColorBox(4)
            setCustomCurser(false)
        }
    };

    useEffect(() => {
        setFirdColor('')
        setSecondColor('')
        setThirdColor('')
        setColorBox(1)
    }, [image])

    return (
        <section className='main-section'>
            <div className='container'>
                <div>
                    <div className='heading'>
                        <h3>Upload an image</h3>
                    </div>
                    <div className='text'>
                        <p>
                            Click “Browse” to select one of your saved images. Drag the cropping circle to the center of your face—be sure to include at least part of your hair. Use the arrows to increase or decrease the dimensions of the circle based on the size of the image.
                        </p>
                    </div>
                </div>
                <div className='upload'>
                    <div className='stepper'>
                        <div className='stepper-item'>
                            <div style={{ width: 24, height: 24, borderRadius: 8, backgroundColor: step === 1 ? '#CD1E5D' : '#7C7C7C' }} />
                            <div className='line' />
                            <div style={{ width: 24, height: 24, borderRadius: 8, backgroundColor: step === 2 ? '#CD1E5D' : step < 2 ? '#E6E6E6' : '#7C7C7C' }} />
                            <div className='line' />
                            <div style={{ width: 24, height: 24, borderRadius: 8, backgroundColor: step === 3 ? '#CD1E5D' : step < 3 ? '#E6E6E6' : '#7C7C7C' }} />
                            <div className='line' />
                            <div style={{ width: 24, height: 24, borderRadius: 8, backgroundColor: step === 4 ? '#CD1E5D' : step < 4 ? '#E6E6E6' : '#7C7C7C' }} />
                        </div>
                        <div className='stepper-name'>
                            <p style={{ width: 180, textAlign: 'center', lineHeight: 0 }}>Your Image</p>
                            <p style={{ width: 180, textAlign: 'center', lineHeight: 0 }}>Your Image</p>
                            <p style={{ width: 180, textAlign: 'center', lineHeight: 0 }}>Your Image</p>
                            <p style={{ width: 180, textAlign: 'center', lineHeight: 0 }}>Your Image</p>
                        </div>
                    </div>
                </div>
                {step === 1 &&
                    <div style={{ width: windowWidth > 740 ? 720 : windowWidth - 20, height: windowWidth > 740 ? 720 : windowWidth - 20 }} className='upload-container'>
                        {image ?
                            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <canvas
                                    ref={canvasRef}
                                    width={image.width < 720 ? image.width : 720}
                                    height={image.height < 720 ? image.height : 720}
                                    style={{ marginRight: 12, backgroundColor: 'white', borderRadius: 12, cursor: isDragging || isResizing ? 'grabbing' : 'grab' }}
                                    onMouseDown={handleMouseDown}
                                    onMouseUp={handleMouseUp}
                                    onMouseMove={handleMouseMove}
                                    onMouseOut={handleMouseOut}
                                // style={{ cursor: isDragging || isResizing ? 'grabbing' : 'grab' }}
                                ></canvas>
                            </div>

                            : <>
                                <FileUploader handleChange={handleImageUpload} name="file" types={fileTypes} />
                                <div className='button'>
                                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1136_24058)"><path d="M58.3318 5H1.66668C0.7467 5 0 5.74654 0 6.66652V56.6653C0 57.5852 0.7467 58.3319 1.66668 58.3319H58.332C59.2519 58.3319 59.9986 57.5852 59.9986 56.6653V6.66652C59.9985 5.74654 59.2518 5 58.3318 5ZM56.6653 54.9987H3.3332V8.3332H56.6651V54.9987H56.6653Z" fill="#BDBDBD"></path><path d="M79.2572 31.9471L59.2577 18.6142C58.501 18.1075 57.461 18.3109 56.9478 19.0774C56.4378 19.8441 56.6478 20.8774 57.411 21.3874L76.1038 33.8504L54.3811 71.0828L25.8118 55.21C25.0086 54.76 23.9919 55.0467 23.5419 55.8567C23.0952 56.6634 23.3852 57.6766 24.1886 58.1233L54.1878 74.7896C54.4445 74.9329 54.7211 74.9996 54.9978 74.9996C55.5711 74.9996 56.1278 74.7029 56.4378 74.1729L79.7705 34.1739C80.2172 33.4105 79.9905 32.4338 79.2572 31.9471Z" fill="#BDBDBD"></path><path d="M29.9997 18.334C24.4866 18.334 20 22.8206 20 28.3337C20 33.8469 24.4866 38.3335 29.9997 38.3335C35.5129 38.3335 39.9995 33.8469 39.9995 28.3337C39.9995 22.8206 35.5129 18.334 29.9997 18.334ZM29.9997 35.0003C26.3231 35.0003 23.3332 32.0104 23.3332 28.3337C23.3332 24.6571 26.3231 21.6672 29.9997 21.6672C33.6764 21.6672 36.6663 24.6571 36.6663 28.3337C36.6663 32.0104 33.6764 35.0003 29.9997 35.0003Z" fill="#BDBDBD"></path><path d="M29.9983 35C20.8086 35 13.332 42.4765 13.332 51.6663V56.6662C13.332 57.5861 14.0787 58.3328 14.9987 58.3328H44.998C45.9179 58.3328 46.6646 57.5861 46.6646 56.6662V51.6663C46.6646 42.4764 39.1881 35 29.9983 35ZM43.3313 54.9995H16.6654V51.6663C16.6654 44.3132 22.6452 38.3334 29.9983 38.3334C37.3514 38.3334 43.3313 44.3132 43.3313 51.6663V54.9995V54.9995Z" fill="#BDBDBD"></path></g><defs><clipPath id="clip0_1136_24058"><rect width="80" height="80" fill="white"></rect></clipPath></defs></svg>
                                    <button className='browse-button' style={{ width: 'fit-content', height: 'fit-content', padding: '12px 36px', border: 'none', borderRadius: 28, fontSize: 24 }}>Browse</button>
                                </div>
                            </>}
                        {image && <button onClick={() => setImage(false)} style={{ position: 'absolute', top: 12, right: 12, zIndex: 100 }}>X</button>}
                    </div>
                }
                {step === 1 && <button
                    className='start-button'
                    style={{ width: 'fit-content', height: 'fit-content', padding: '16px 24px', borderRadius: 28, marginTop: 48, fontSize: 18 }} onClick={handleCropImage}>Get Started
                </button>}
                {step === 2 &&
                    <>
                        {customCurser && <div className="custom-cursor" style={{ left: `${position.x}px`, top: `${position.y}px` }}>
                            <div style={{ borderColor: selectedColor ? selectedColor : 'white' }} className="ring"></div>
                            <div className="plus">+</div>
                        </div>}
                        <div style={{ display: 'flex', gap: 24 }}>
                            <canvas
                                ref={resultCanvasRef}
                                width={420}
                                height={420}
                                style={{ borderRadius: 16 }}
                                onClick={handleCanvasClick}
                                onMouseMove={handleCanvas}
                                onMouseEnter={() => colorBox < 4 && setCustomCurser(true)}
                                onMouseOut={() => setCustomCurser(false)}
                            ></canvas>
                            <div>
                                <div onClick={() => setColorBox(1)} style={{ width: 100, height: 100, backgroundColor: firstColor ? firstColor : '#e9e9e9', marginBottom: 16, borderRadius: 12 }} />
                                <div onClick={() => setColorBox(2)} style={{ width: 100, height: 100, backgroundColor: secondColor ? secondColor : '#e9e9e9', marginBottom: 16, borderRadius: 12 }} />
                                <div onClick={() => setColorBox(3)} style={{ width: 100, height: 100, backgroundColor: thirdColor ? thirdColor : '#e9e9e9', borderRadius: 12 }} />
                            </div>
                        </div>
                        <div style={{ marginTop: 48 }}>
                            <button className='previous-button' onClick={() => { setStep(1) }}>Previous</button>
                            <button className='next-button' onClick={() => alert('Under Development')}>Next</button>
                        </div>
                    </>
                }
            </div>
        </section >
    )
}

export default UploadSection