import React from 'react';
import './imagesection.css'

const ImageSection = () => {
    return (
        <section className='main-container'>
            <div className='container'>
                <div className='main-text'>
                    <h3 style={{ fontSize: 34, margin: 0, fontWeight: 400, marginBottom: 24 }}>Color-analyze yourself</h3>
                </div>
                <div className='text-grid'>
                    <div className='text-grid-item'>
                        <p style={{ marginTop: 0 }}>
                            ferendis quas omnis dolor velit, dolorum sed error esse! Adipisci, ex. Nostrum aspernatur architecto nemo debitis officia repellendus similique?
                            dolorum sed error esse! Adipisci, ex. Nostrum aspernatur architecto nemo debitis officia repellendus sim
                        </p>
                    </div>
                    <div className='text-grid-item'>
                        <p style={{ marginTop: 0 }}>
                            ferendis quas omnis dolor velit, dolorum sed error esse! Adipisci, ex. Nostrum aspernatur architecto nemo debitis officia repellendus similique?
                            dolorum sed error esse! Adipisci, ex. Nostrum aspernatur architecto nemo debitis officia repellendus sim
                        </p>

                    </div>
                </div>
                <div className="grid-container">
                    <div className="grid-item">
                        <img
                            style={{ width: '100%' }}
                            src="https://colorwise.me/images/celebs/min/summer.9014b9ec.jpeg"
                            alt=""
                            srcset=""
                        />
                    </div>
                    <div className="grid-item">
                        <img
                            style={{ width: '100%' }}
                            src="https://colorwise.me/images/celebs/min/summer.9014b9ec.jpeg"
                            alt=""
                            srcset=""
                        />
                    </div>
                    <div className="grid-item">
                        <img
                            style={{ width: '100%' }}
                            src="https://colorwise.me/images/celebs/min/summer.9014b9ec.jpeg"
                            alt=""
                            srcset=""
                        />
                    </div>
                    <div className="grid-item">
                        <img
                            style={{ width: '100%' }}
                            src="https://colorwise.me/images/celebs/min/summer.9014b9ec.jpeg"
                            alt=""
                            srcset=""
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImageSection;