import React from 'react'
import './footer.css'
import { BsFacebook, BsTwitter, BsInstagram, BsApple } from 'react-icons/bs'
import { BiLogoPlayStore } from 'react-icons/bi'

const FAQSection = () => {
    return (
        <div className='footer-main'>
            <div className='footer'>
                <div className='first'>
                    <div style={{ display: 'flex', gap: 24 }}>
                        <div className='icon' style={{ fontSize: 40 }}><BsFacebook /></div>
                        <div className='icon' style={{ fontSize: 40 }}><BsTwitter /></div>
                        <div className='icon' style={{ fontSize: 40 }}><BsInstagram /></div>
                    </div>
                </div>
                <div className='second'>
                    <div className='footer-text'>Privacy Policy</div>
                    <div className='footer-text'>Community</div>
                    <div className='footer-text'>Contact Us</div>
                </div>
                <div style={{ display: 'flex', gap: 24 }} className='third'>
                    <div className='icon' style={{ fontSize: 40 }}><BiLogoPlayStore /></div>
                    <div className='icon' style={{ fontSize: 40 }}><BsApple /></div>
                </div>
            </div>
        </div>
    )
}

export default FAQSection;