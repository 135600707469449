import React from 'react'
import './navbar.css'

const Navbar = () => {
    return (
        <nav className='main-navbar'>
            <div className='navbar'>
                <ul className='nav-list'>
                    <a href='#' style={{ textDecoration: 'none', color: '#D92A67' }} className='nav-logo'>Logo Here</a>
                    <div className='navbar-inner-item'>
                        <li onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className='nav-item'>Home</li>
                        <li onClick={() => window.scrollTo({ top: 700, behavior: 'smooth' })} className='nav-item'>Best Colors</li>
                        <li className='nav-button'>Login</li>
                    </div>
                </ul>
            </div >
        </nav >
    )
}

export default Navbar